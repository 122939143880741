.titleContainer {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.formContent {
  align-items: center;
  display: flex;
  flex-direction: column;

  .desc {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--app-text-color);
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .bottomCancelButton,
    .bottomButton {
      border-radius: 6px;
      min-width: 200px;
      height: 40px;
      color: var(--foreground-rgb);
      background: linear-gradient( 270deg, #7141F9 0%, #1E85FF 100%);
    }

    .bottomCancelButton {
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      color: #000;
    }
  }

  .columnDirection {
    flex-direction: column-reverse;
    gap: 12px;

    .bottomCancelButton,
    .bottomButton {
      min-width: 300px;
    }
  }

}

